import {
  chakra,
  ImageProps,
  forwardRef,
  useColorModeValue,
} from '@chakra-ui/react';
import logo from './logo.svg';

export const Logo = forwardRef<ImageProps, 'img'>((props, ref) => {
  /*
  const prefersReducedMotion = usePrefersReducedMotion()

  const animation = prefersReducedMotion
    ? undefined
    : `${spin} infinite 20s linear`
*/
  const isLight = useColorModeValue('dark', 'light') === 'light';
  return (
    <chakra.img
      filter={isLight ? '' : 'brightness(0.5)'}
      src={logo}
      ref={ref}
      {...props}
    />
  );
});
