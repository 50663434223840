import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Grid,
  theme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3}>
        <ColorModeSwitcher justifySelf="flex-end" />
        <VStack spacing={8}>
          <Logo h="40vmin" pointerEvents="none" />
          <Text>

            Welcome to <code>silentech</code>, where computers and ideas meet.
          </Text>
          <Text>
            You will also find a cat.
          </Text>
          <Link
            color="teal.500"
            href="https://code.silentech.gr"
            fontSize="2xl"
            target="_blank"
            rel="noopener noreferrer"
          >
            More silentech
          </Link>
        </VStack>
      </Grid>
    </Box>
  </ChakraProvider>
);
